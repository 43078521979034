import React from "react";
//import Qrcode from './Qrcode';
import { connect } from 'react-redux';
import { api_url } from '../config';
/* import  '../css/passcode.css';
import  '../css/qrcode.css' */;
var QRCode = require('qrcode.react');
const queryString = require('query-string');
class Passcode extends React.Component {
	constructor(props) {

		super(props);
		this.state = {
			qrcodeurl: '',
			storedet: '',
			prodlist: [],
			prod_id: '',
			loginmode: 1,
			prod_count: '',
			qrcode_rand_mode: 0,
			res_status: '',
			qrcode_link_status: 0,
			parent_url: document.referrer,
			curr_unavail_reward: 'Sorry! This page is currently unavailable.'
		}


	}
	componentWillMount() {
		import('../css/qrcode.css');
	}

	componentDidMount() {
		const store_id = this.props.match.params.id;
		const prod_id = this.props.match.params.prod_id;
		const postdata = new FormData();
		postdata.append("store_id", store_id);
		if (prod_id !== '' && typeof prod_id !== 'undefined') {
			postdata.append("prod_id", prod_id);
		}
		const value = queryString.parse(this.props.location.search);
		const ifrval = value.ifr;
		if (ifrval === '1') {
			postdata.append("iframe_mode", 1);
		}
		//alert(window.parent.location);
		fetch(api_url + "/api/fetchstore", {
			method: "POST",
			body: postdata
		})
			.then(res => res.json())
			.then(
				(result) => {
					/* if (prod_id !== '' && typeof prod_id !== 'undefined') {
						this.props.PasscodeChange('Match');
						if(prod_id1 === 'dt'){
							this.setState({
								qrcodeurl: result.qrcode_url,
								loginmode: 1
							});
						}else{
							this.setState({
								qrcodeurl: result.qrcode_url,
								prod_id: prod_id,
								loginmode: 0
							});
							
						}	
					} */
					if (result.code === 200) {
						this.setState({
							storedet: result,
							storename: "",
							prodlist: result.prod_list,
							prod_id: result.prod_id,
							prod_count: result.prod_count,
							res_status: result.code,
							qrcode_link_status:result.qrcode_link_status,
							qrcode_rand_mode:result.qrcode_rand_mode
						});
						if ((prod_id !== '' && typeof prod_id !== 'undefined') || prod_id === 'dt') {
							this.props.PasscodeChange('Match');
							this.setState({
								qrcodeurl: result.qrcode_url,
								prod_id: prod_id,
								loginmode: 0
							});
							
							if (prod_id === 'dt') {
								this.setState({
									loginmode: 1
								});
							}
							if(this.state.qrcode_rand_mode === 1){
								setInterval(this.qrcodegenerator, 30000);
							}	

						}
					} else {
						this.setState({
							res_status: result.code,
							store_not_avail: result.store_not_avail
						});
					}
					//console.log( JSON.parse(result.prod_list));
					this.props.updateRetarget(store_id, 'store');
					this.props.StorenameUpdate('storename', result.name);
				},

				(error) => {
					this.setState({

					});
				}
			)


	}
	qrcodegenerator = () => {
		const store_id = this.props.match.params.id;
		const postdata = new FormData();
		postdata.append("store_id", store_id);
		postdata.append("prod_id", this.state.prod_id);
		fetch(api_url + "/api/qrcodegenerator", {
			method: "POST",
			body: postdata
		})
			.then(res => res.json())
			.then(
				(result) => {
					if (result.status === 200) {
						this.setState({
							qrcodeurl: result.qrcode_url,
						});

					}
				},
				(error) => {
					this.setState({

					});
				}
			)

	}
	productChange(prod_id) {
		const store_id = this.props.match.params.id;
		const postdata = new FormData();
		postdata.append("store_id", store_id);
		postdata.append("prod_id", prod_id);
		fetch(api_url + "/api/changeproduct", {
			method: "POST",
			body: postdata
		})
			.then(res => res.json())
			.then(
				(result) => {
					if (result.status === 1) {
						this.setState({
							qrcodeurl: result.qrcode_url,
							prod_id: prod_id
						});

					}
				},
				(error) => {
					this.setState({

					});
				}
			)

	}

	handleChange(value) {
		const store_id = this.props.match.params.id;
		if (value.length === 4) {
			const postdata = new FormData();
			postdata.append("store_id", store_id);
			postdata.append("secret_code", value);
			postdata.append("prod_id", this.state.prod_id);
			fetch(api_url + "/api/checksecretcode", {
				method: "POST",
				body: postdata
			})
				.then(res => res.json())
				.then(
					(result) => {
						if (result.status === 1) {
							this.props.PasscodeChange('Match');
							console.log(result.qrcode_url);
							this.setState({
								qrcodeurl: result.qrcode_url,
								qrcode_link_status:result.qrcode_link_status
							});
							
							if(this.state.qrcode_rand_mode === 1){
								setInterval(this.qrcodegenerator, 30000);
							}

						}
					},
					(error) => {
						this.setState({

						});
					}
				)
		}

	}

	render() {

		const styleforqr = {
			header_text: {
				color: this.state.storedet.header_text_color
			},


			footer_text: {

				color: this.state.storedet.footer_text_color
			},
			header_bg: {

				backgroundColor: this.state.storedet.header_bg_color
			},
			footer_bg: {

				backgroundColor: this.state.storedet.footer_bg_color,
				color: this.state.storedet.product_text_color
			},
			product_txt_col:{
				
				color: this.state.storedet.product_text_color
			},	
			body_bg: {

				backgroundColor: this.state.storedet.body_color
			},
			login_box_bg: {

				backgroundColor: this.state.storedet.login_box_bg
			},
			body_bg_ifr: {

				backgroundColor: '#fff'
			},
			qr_text: {
				color: this.state.storedet.qrcode_text_color
			},
			txt_secret_code: {
				borderBottom: '1px solid ' + this.state.storedet.header_bg_color,
				color: this.state.storedet.header_bg_color
			},
			header_text_bor: {
				borderBottom: '1px solid ' + this.state.storedet.header_text_color,
				color: this.state.storedet.header_text_color
			},
			img_logo: {
				maxWidth: '150px'
			},
			redeem_ifr: {
				marginTop: '30px'
			},

		};

		return (
			<div>
				{(this.state.res_status === 200) ? (
					<div className="div-main-bg" style={styleforqr.body_bg}>
						<span style={{ display: 'none' }}>test{this.props.storename}</span>
						{/* {this.state.storedet.store_logo &&
						// <div>
						// 	<div className="row">
						// 		<div className="col-md-12 div-reward-mob-logo qw">
						// 			<img style={styleforqr.img_logo} src={this.state.storedet.store_logo}></img>
						// 		</div>
						// 	</div>
						// 	<div style={{ "clear": "both" }}></div>
						// </div>
					} */}
						<div className="div-select-product">
							{(this.props.passcodeMode !== 0 && this.state.loginmode === 1 && this.state.prod_count > 1) &&
								<div style={{ width: '15%' }}>
									<select  onChange={(e) => this.productChange(e.target.value)} className="form-control form-control-lg" style={styleforqr.footer_bg}>
										{this.state.prodlist.map((prod, index) => (
											<option key={prod.id} value={prod.id}>{prod.title}</option>
										))}
									</select>
								</div>
							}
						</div>

						<div className={"div-redeem-iframe " + (this.state.parent_url !== '' ? 'red_mtop' : '')}>
							{this.state.storedet.store_logo &&
								<div className="div-reward-mob-logo qw">
									<div className="div-reward-logo" style={styleforqr.footer_bg}>
										<img style={styleforqr.img_logo} alt="logo" src={this.state.storedet.store_logo}></img>
									</div>
								</div>
							}
							<div className="div-green" style={styleforqr.header_bg}>
								<div style={styleforqr.header_text_bor} className="div-head-cont" dangerouslySetInnerHTML={{ __html: this.state.storedet.text1 }}>

								</div>
								<div style={styleforqr.header_text} className="div-middle-cont" dangerouslySetInnerHTML={{ __html: this.state.storedet.text2 }}>

								</div>
							</div>
							{this.props.passcodeMode === 0 ? (
								<div className="div-code-cust-four" >
									<div className="div-for-four">
										<div className="div-four-box" style={styleforqr.login_box_bg}>
											<div style={styleforqr.qr_text} className="div-for-four-head" dangerouslySetInnerHTML={{ __html: this.state.storedet.text3 }}></div>
											<div style={styleforqr.qr_text} className="div-for-four-copy m_top1" dangerouslySetInnerHTML={{ __html: this.state.storedet.text4 }}></div>
											<div className="m_top2">
												<input style={styleforqr.txt_secret_code} className="txt-secret-code" autoComplete="off" maxLength="4" name="passcode" placeholder={this.state.storedet.text5} onChange={(e) => this.handleChange(e.target.value)} />
											</div>
											<div style={styleforqr.qr_text} className="div-for-four-copy m_top1" dangerouslySetInnerHTML={{ __html: this.state.storedet.text6 }}></div>
										</div>
									</div>
								</div>
							) : (

								<div className="div-code-cust">
									<div className="div-qr-code">
										<QRCode value={this.state.qrcodeurl} className="qrcode-ht-width" />
									</div>
								</div>

							)}

							<div className="div-grey" style={styleforqr.footer_bg}>
								<div className="div-footer-cont">
									<div>
										<span style={styleforqr.footer_text} className="" dangerouslySetInnerHTML={{ __html: this.state.storedet.text7 }}>

										</span>
									</div>
									<div className="m_top1">
										<span style={styleforqr.footer_text} className="" dangerouslySetInnerHTML={{ __html: this.state.storedet.text8 }}>

										</span>
										
									</div>
									{(this.state.qrcode_link_status === "1" && this.state.qrcodeurl !== '') && 
									<div className="m_top1 txt-center">
									<a className="qrcode_link" style={styleforqr.footer_text} href={this.state.qrcodeurl}>{this.state.storedet.text9}</a>
									</div>
									}
								</div>
							</div>
						</div>
					</div>
				) : (this.state.res_status === 201) ? (

					<div>
						<span className="curr_unavail">{this.state.curr_unavail_reward}</span>
						<center><img alt="noimage" style={{ width: '80%' }} src={api_url + "/public/img/empty.png"} /></center>

					</div>
				) : (
					<div></div>
				)}

			</div>



		);
	}
}


const mapStateToProps = (state) => {
	return {
		passcodeMode: state.passcode.passcodeMode,
		storename: state.passcode.storename
	}

}
const mapDispatchToProps = (dispatch) => {
	return {
		PasscodeChange: (val) => {
			dispatch({ type: val })
		},
		StorenameUpdate: (action, val) => {
			dispatch({
				type: action,
				payval: val
			});
		}
	}

}
export default connect(mapStateToProps, mapDispatchToProps)(Passcode);
