import React from 'react';
import {render} from 'react-dom';
//import Main from './components/Main';
import Passcode from './components/Passcode';
import PasscodeCookie from './components/PasscodeCookie';
import ViewRewards from './components/ViewRewards';
import Giftpage from './components/Giftpage';
//import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import store from './store';
//import {connect} from 'react-redux';
//import { BrowserRouter, Route, Link,Redirect } from "react-router-dom";
import { BrowserRouter,Route} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Helmet} from "react-helmet";
//import Script from "react-inline-script"
//import {connect} from 'react-redux';
//var QRCode = require('qrcode.react');
import InnerHTML from 'dangerously-set-html-content';
import { api_url } from './config';


class App extends React.Component {
	
  constructor() {
      super();
      this.state = {
        storename:"",
        storeid:'',
        retar:''
		

      };
	  this.updateStoreName = this.updateStoreName.bind(this);
	  this.updateRetarget = this.updateRetarget.bind(this);
	  //this.StoreNameChange('test');
  }
  
  componentDidMount() {
		   //const store_id = this.props.match.params.id;
		  console.log(this.props);
  
   }
  changeUsername(newName){
		const token ='Karthick';
	    sessionStorage.setItem('token', token);
		this.setState({
            strorename: newName
        });
  }
  updateStoreName(storename){
		this.setState({
				storename:storename
		});
	
  }
  updateRetarget(id,type){
		/* this.setState({
				storeid:store_id,
				load_script:'<script>alert("OK");</script>'
		});
		console.log("mrk_ttet"+store_id); */
		
		const postdata = new FormData();
		postdata.append("id", id);
		postdata.append("type", type);
		  fetch(api_url+"/api/fetchretargeting",{
				method: "POST",
				body: postdata
		  })
		  .then(res => res.json())
		  .then(
			(result) => {
			 
			  this.setState({
				//storeid:store_id,
				retar:result,
			  });
			  
			// alert(this.state.result_val.ogtitle) ;
			  
			if(result.sbet !== ''){  
				var script = document.createElement("script");
				script.type="text/javascript";
				script.innerHTML=result.siht;
				document.getElementsByTagName('head')[0].appendChild(script);
			}
				
			},
			
			(error) => {
			  
			}
		  )
		
  }
  handleChange(value) {
		console.log(value);
		/* store.dispatch({
			  type:'L',
			  payload:100
		});  */
		this.setState({
            //isPasscode: store.getState().isPasscode
            isPasscode: 1
        });
   }	
  render(){
	 // const storename =this.state.storename;
	  const storename = store.getState();
	 //console.log(storename);
	  //let storename =''
      return ( 
		
		<BrowserRouter>
		
	    <Provider store={store}>
		<div>
			<Helmet>
                <meta charSet="utf-8" />
				{this.state.retar.tab_title && <title>{this.state.retar.tab_title}</title> }
              
				<script src="//ajax.googleapis.com/ajax/libs/jquery/1.11.3/jquery.min.js" type="text/javascript"></script>
				
				{this.state.retar.favicon &&
					<link rel="icon" type="image/x-icon" href={this.state.retar.favicon} />
				}
				
				{this.state.retar.ogtitle && <meta name="og:title" content={this.state.retar.ogtitle} />}
				{this.state.retar.ogtitle && <meta itemprop="name" content={this.state.retar.ogtitle} />}
           
				{this.state.retar.ogdes  && <meta property="og:description" content={this.state.retar.ogdes} />}
				{this.state.retar.ogdes  && <meta itemprop="description" content={this.state.retar.ogdes} />}

				{this.state.retar.ogimage  && <meta itemprop="image" content={this.state.retar.ogimage} />}
				{this.state.retar.ogimage  && <meta property="og:image" content={this.state.retar.ogimage} />}
				
				
            </Helmet>	
			{this.state.retar.sbbt  && <InnerHTML html={this.state.retar.sbbt} />}
			 
			<Route  exact path={`${process.env.PUBLIC_URL}/:id`} render={(props) => <Passcode  {...props} updateRetarget={this.updateRetarget} updateStoreName={this.updateStoreName} /> } />
			

			
			<Route exact path={`${process.env.PUBLIC_URL}/product/:id/:prod_id?`} render={(props) => <Passcode  {...props} updateRetarget={this.updateRetarget} updateStoreName={this.updateStoreName} /> } />
			
			
			<Route exact path={`${process.env.PUBLIC_URL}/:store_id/:prod_id/:storename/:rkey`} render={(props) => <PasscodeCookie  {...props}  updateRetarget={this.updateRetarget} /> } />
			<Route exact path={`${process.env.PUBLIC_URL}/viewrewards/:prod_id/:store_id`} render={(props) => <ViewRewards  {...props}  updateRetarget={this.updateRetarget} /> } />
			
			<Route exact path={`${process.env.PUBLIC_URL}/g/:gift_id`} render={(props) => <Giftpage  {...props} updateRetarget={this.updateRetarget} /> } />
			
			{this.state.retar.sbet && <InnerHTML html={this.state.retar.sbet} />}
		
		</div>
		</Provider>
		</BrowserRouter>
		
			
      );

  }

}



/* 
<Route exact path={`${process.env.PUBLIC_URL}/samtest`} component={Samtest}></Route>
<Route exact path="/:id" render={(props) => <Passcode  {...props} updateStoreName={this.updateStoreName} /> } />

const mapStateToProps = (state) => {
	return {
		storename:state.passcode.storename
		
	}	
	
}
const mapDispatchToProps = (dispatch) => {
	return {
		StoreNameChange:(storename)=>{
			dispatch({type:'storename',storename})
		},	
		
	}	
	
}
export default connect(mapStateToProps,mapDispatchToProps)(App);  */

/*  const mapStateToProps = (state) => {
	return {
		passcodeMode:state.passcode.passcodeMode
	}	
	
}

export default connect(mapStateToProps,null)(App); */ 

/* 
{props.isPasscode == 1 ? (
				<Qrcode  />
			  ) : (
				<Passcode />
			  )}
 <span>isLoggedIn {store.getState().isLoggedIn}</span>
<button onClick={() => this.namechange()}>+Add</button>
<button style={{display:'none'}} onClick={() => this.handleChange(1)}>+Add</button>
<QRCode value="http://facebook.github.io/react/" />,
<Route path="/main" render={(props) => getToken() ? <Main {...props} /> : <Redirect to={{ pathname: '/test' }} />} />
<Route
      {...rest}
      render={(props) => !getToken() ? <Component {...props} /> : <Redirect to={{ pathname: '/dashboard' }} />}
    /> */
//https://stackblitz.com/edit/login-app-reactjs?file=Utils%2FPublicRoute.js	
/* 
  <Main   changeUsername={this.changeUsername.bind(this)} />
ReactDOM.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
    document.getElementById('root')
); */
render(<App />, window.document.getElementById('root'));
export default App;
 
// import { createStore } from 'redux';
// const initialState={
  // result:1,
  // lastValue:[]

// }
// const reducer = (state=initialState,action) => {

  // switch (action.type) {
    // case 'ADD':
      // state={
          // ...state,
          // result : state.result + action.payload,
          // lastValue:[...state.lastValue,action.payload]
      // }
     
      // break;
    // case 'SUBTRACT':
      // state={
        // ...state,
        // result : state.result - action.payload,
        // lastValue:[...state.lastValue,action.payload]
      // }
       // break;  
    
  // }
  // return state;

// };

// const store=createStore(reducer);
// store.subscribe(() => {

  // console.log("Store Updates",store.getState());

// });
// store.dispatch({
  
  // type:'ADD',
  // payload:100
  
// });
// store.dispatch({
  
  // type:'ADD',
  // payload:22
  
// });
// store.dispatch({
  
  // type:'SUBTRACT',
  // payload:80
  
// });