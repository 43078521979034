import * as redux from 'redux';
//export const COUNTER_INCREMENT = 'counter/increment';
//export const COUNTER_DECREMENT =  'counter/decrement';
const countinitialState={
  count:0,
  
}
const countReducer = (state=countinitialState,action) => {
	switch (action.type) {
		case 'increment':
		  state={
			   ...state,
			   count : state.count+1
		 }
		break;
		case 'decrement':
		  state={
			   ...state,
			   count : state.count-1
		 }
		break;
		default:
		return state;
			
	}
	return state;

};
const passcodeInitialState={
  passcodeMode:0,
  storename:'',
  memberCookie:'',
  rewardCookie:''
  
}
const passcodeReducer = (state=passcodeInitialState,action) => {
	
	switch (action.type) {
		case 'Match':
		  state={
			   ...state,
			   passcodeMode : 1
		 }
		break;
		case 'NotMatch':
		  state={
			   ...state,
			   passcodeMode : 0
		 }
		break;
		case 'storename':
		  state={
			   ...state,
			   storename : action.payval
		 }
		break;
		case 'memberCookie':
		  state={
			   ...state,
			   memberCookie :action.payval
		 }
		break;
		case 'rewardCookie':
		  state={
			   ...state,
			   rewardCookie :action.payval
		 }
		break;
		default:
		return state;
			
	}
	return state;

};





const rootReducer = (state={},action) => {
	return {
		counter:countReducer(state.counter,action),
		passcode:passcodeReducer(state.passcode,action)
	}	
	
}	
const store=redux.createStore(rootReducer);
export default store;
