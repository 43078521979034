import React from "react";
import { connect } from 'react-redux';
import { api_url } from '../config';
import cookie from 'react-cookies';
import { Link } from "react-router-dom";
import '../css/stamp_page.css';
//import { Container, Row, Col } from 'react-bootstrap';
//import Button from 'react-bootstrap/Button';
import 'font-awesome/css/font-awesome.min.css';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Modal from 'react-modal';
class PasscodeCookie extends React.Component {

	constructor(props) {

		super(props);
		console.log(props);
		const store_id = this.props.match.params.store_id
		const prod_id = this.props.match.params.prod_id
		this.props.updateRetarget(store_id, 'reward');
		this.state = {
			store_id: store_id,
			prod_id: prod_id,
			stamp_select_cnt: '',
			tcolor: '#ccc',
			total_points: '',
			stamp_end_cnt: '',
			earned_pts: 0,
			showAnim: '',
			cont_loader: 0,
			fields: {
				name: '',
				email: '',
				phone: '',
				gdpr_check_mode: ''
			},
			errors: {
				name: '',
				email: '',
				phone: '',
				gdpr_check_mode: ''
			},
			stampdata_display: '',
			store_det: '',
			modalIsOpen: false,
			setIsOpen: false,
			modaltext: '',
			modaltitle: '',
			gdpr_status: 0,
			gdpr_validity: 0,

		}

		this.loadStampPage = this.loadStampPage.bind(this);
		this.phoneChange = this.phoneChange.bind(this);
		//  alert(this.props.match.params.store_id);

	}
	phoneChange(phone_number) {
		let fields = { ...this.state.fields };
		fields['phone'] = phone_number;
		this.setState({ fields: fields });
	}
	handleChange1(field, e) {
		let fields = { ...this.state.fields };
		let errors = this.state.errors;
		fields[field] = e.target.value;
		errors[field] = '';
		this.setState({ fields: fields });
		this.setState({ errors });

	}

	handleValidation() {
		let fields = { ...this.state.fields };
		let errors = {};
		let formIsValid = true;
		//console.log(fields["email"]);
		if (!fields["email"]) {
			formIsValid = false;

		}
		if (fields["name"] === '' || typeof fields["name"] === "undefined") {
			formIsValid = false;
			errors["name"] = 1;
		}
		if (typeof fields["email"] !== "undefined") {
			let lastAtPos = fields["email"].lastIndexOf('@');
			let lastDotPos = fields["email"].lastIndexOf('.');

			if (!(lastAtPos < lastDotPos && lastAtPos > 0 && fields["email"].indexOf('@@') === -1 && lastDotPos > 2 && (fields["email"].length - lastDotPos) > 2)) {
				formIsValid = false;
				//errors["email"] = "Email is not valid";
				errors["email"] = 1;
			}
		}
		if(this.state.store_det.phone_mandatory==='1'){
			if (fields["phone"] === '' || typeof fields["phone"] === "undefined") {
				formIsValid = false;
				errors["phone"] = 1;
			} 
		}
		if(this.state.store_det.gdpr_validity===1){
			if (fields["gdpr_check_mode"] === '' || typeof fields["gdpr_check_mode"] === "undefined") {
				formIsValid = false;
				errors["gdpr_check_mode"] = 1;
			} 
		}
		this.setState({ errors: errors });
		return formIsValid;

	}
	componentDidMount() {

		const store_id = this.props.match.params.store_id;
		const prod_id = this.props.match.params.prod_id;
		const rkey = this.props.match.params.rkey;
		const memberCookie = cookie.load('memberCookie_' + store_id);
		//alert(memberCookie);
		if (memberCookie !== '' && typeof memberCookie !== 'undefined') {

			this.props.CookieUpdate('memberCookie', memberCookie);
			const postdata = new FormData();
			postdata.append("fk_member_id", memberCookie);
			postdata.append("fk_store_id", store_id);
			postdata.append("prod_id", prod_id);
			postdata.append("rkey", rkey);

			fetch(api_url + "/api/auto_pts_update", {
				method: "POST",
				body: postdata
			})
				.then(res => res.json())
				.then(
					(result) => {
						const fk_member_id = result.fk_member_id;
						if (result.earned_pts !== 0) {
							this.showanimation(result.earned_pts);
						}

						this.CookieSet('memberCookie_' + store_id, fk_member_id, 'memberCookie', 8760);
						this.loadStampPage(fk_member_id);

					},
					(error) => { }

				)



		} else {
			this.loadStampPage('');
			//document.body.style.backgroundColor = "#0000007F";
		}
	}
	loadStampPage(fk_member_id) {
		/* this.displayData.push(<div  id="display-data"><span>qwrr</span></div>);
		this.displayData.push(<div  id="display-data"><span>qwrr</span></div>);
		this.setState({
		   showdata : this.displayData,
		}); */
	


		const store_id = this.props.match.params.store_id;
		const prod_id = this.props.match.params.prod_id;
		let visitCookierew = cookie.load('visitCookierew_' + store_id);
		if (visitCookierew === '' || typeof visitCookierew === 'undefined') {
			this.CookieSet('visitCookierew_' + store_id, 1, 'visitCookierew', 24);
			visitCookierew = 1;
		} else {
			visitCookierew = '';
		}


		const postdata = new FormData();
		postdata.append("fk_store_id", store_id);
		postdata.append("fk_member_id", fk_member_id);
		postdata.append("prod_id", prod_id);
		postdata.append("visitcookierew", visitCookierew);
		fetch(api_url + "/api/loadstamppage", {
			method: "POST",
			body: postdata
		})
			.then(res => res.json())
			.then(
				(result) => {
						
					//this.stampdisplayData.push(<div  id="display-data"><span>qwrr</span></div>);
					//this.setState({ stampdata : this.stampdisplayData });
					if (fk_member_id !== '' && typeof fk_member_id !== 'undefined') {
					
						//const stamp_end_cnt = result.stamp_end_cnt;
						const stamp_select_cnt = result.stamp_select_cnt;
						const total_points = result.total_points;
						const header_bg_color = result.header_bg_color;
						const row_cnt = result.row_cnt;
						var t = '';
						var k = 1;
						t += '<div class="div-points-box">';
						for (var i = 1; i <= row_cnt; i++) {
							t += '<div class="df">';
							for (var j = 1; j <= 4; j++) {

								if (k <= stamp_select_cnt) {
									t += '<div class="div-box active"><i style="color:' + header_bg_color + '" class="fa fa-thumbs-up"></i></div>';
								} else {
									t += '<div class="div-box"><i style="color:#322e2e" class="fa fa-thumbs-up"></i></div>';
								}

								k++;
							}
							t += '</div>';
						}
						t += '</div>';
						this.setState({ total_points: total_points, stampdata_display: t, store_det: result });
					} else {
					
						this.setState({ store_det: result});
						if(result.gdpr_status === 1){
							this.setState({ gdpr_status: 1 });
						}	

					}

					document.body.style.backgroundColor = result.body_color;



					/* for(var i=1;i<=stamp_end_cnt;i++){
						
						if(i <= stamp_select_cnt){
							
							this.stampdisplayData.push(<div key={i} className="div-box active"><i className="fa fa-check"></i></div>);
						}else{
							this.stampdisplayData.push(<div key={i} className="div-box"><i className="fa fa-check"></i></div>);
						}	
					
					}	 */

					//this.stampdisplayData.push(<div><i className="fa fa-check"></i></div>);




				},
				(error) => {

				}

			)

	}


	CookieSet(cookiename, cookievalue, action, exp) {

		const expires = new Date();
		const exp1 = 1000 * 60 * 60 * exp;
		const maxAge = 60 * 60 * exp;
		expires.setDate(Date.now() + exp1);
		cookie.save(cookiename, cookievalue, { path: '/', expires, maxAge: maxAge });
		this.props.CookieUpdate(action, cookievalue);


	}
	showanimation(earn_points) {
		this.setState({ showAnim: 1 });
		setTimeout(() => {
			this.setState({ showAnim: '' });
		}, 6000);

	}
	openModal(type) {
		 let mtext;
		 let mtitle;
		 if(type === 'privacy'){
			 mtext =this.state.store_det.privacy_content;
			 mtitle =this.state.store_det.privacy_title;
		 }else if(type === 'terms'){
			 mtext =this.state.store_det.terms_content;
			 mtitle =this.state.store_det.terms_title;
		 }	 
		 this.setState({ 
			modalIsOpen: true,
			modaltext :mtext,
			modaltitle :mtitle
			
		 });
	}
	closeModal() {
		this.setState({ 
			modalIsOpen: false,
			modaltext :''
			
		 });

	}
	contactSubmit(e) {
		e.preventDefault();
		const store_id = this.props.match.params.store_id;
		const prod_id = this.props.match.params.prod_id;
		const rkey = this.props.match.params.rkey;
		if (this.handleValidation()) {
			const postdata = new FormData();
			postdata.append("email", this.state.fields.email);
			postdata.append("name", this.state.fields.name);
			postdata.append("phone", this.state.fields.phone);
			postdata.append("store_id", this.props.match.params.store_id);
			postdata.append("prod_id", prod_id);
			postdata.append("rkey", rkey);
			if(this.state.gdpr_status === 1){
				let gdpr_check_mode = this.state.fields.gdpr_check_mode;
				postdata.append("gdpr_status", 1);
				if(typeof gdpr_check_mode === 'undefined' || gdpr_check_mode === '') {
					gdpr_check_mode = 0;
				}else{
					gdpr_check_mode = 1;
				}
				postdata.append("gdpr_check_mode", gdpr_check_mode);	
			}	
			
			
			this.setState({ cont_loader: 1 });

			fetch(api_url + "/api/emailupdate", {
				method: "POST",
				body: postdata
			})
				.then(res => res.json())
				.then(
					(result) => {

						const fk_member_id = result.fk_member_id;
						if (result.earned_pts !== 0) {
							this.showanimation(result.earned_pts);
						}
						this.CookieSet('memberCookie_' + store_id, fk_member_id, 'memberCookie', 8760);

						//const rurl='/rewardstamp/'+store_id;
						//this.props.history.push(rurl);
						/* this.setState({
						   showdata:"<span>test</span>"
						}); */
						this.setState({ cont_loader: 0 });
						this.loadStampPage(fk_member_id);


					},
					(error) => {
						this.setState({

						});
					}
				)

		}
	}

	render() {
		const customStyles = {
		  content : {
			top                   : '35%',
			left                  : '50%',
			right                 : 'auto',
			bottom                : 'auto',
			marginRight           : '-50%',
			transform             : 'translate(-50%, -50%)',
			maxWidth             : '850px',
			maxHeight            : '450px'
		  }
		};
		const styles = {
			btncontinueb: {
				padding: '8px 12px',
				color: '#fff',
				transition: '0.6s',
				border: "1px solid " + this.state.store_det.header_bg_color,
				backgroundColor: this.state.store_det.header_bg_color + '33',
			},
			viewrewards: {

				border: "1px solid " + this.state.store_det.header_bg_color,
				backgroundColor: this.state.store_det.header_bg_color + '33',
				color: this.state.store_det.header_bg_color,
			},
			pts_style: {

				color: this.state.store_det.header_bg_color,
			},
			privacy_style: {
                cursor:'pointer',
				color: this.state.store_det.header_bg_color,
			},
			
			containerow: {
				width: '100%',
				paddingRight: '15px',
				paddingLeft: '15px',
				marginRight: 'auto',
				marginLeft: 'auto',
			},
			btn_rewardmob: {
				width: '100%',
				border: '1px solid #E5E5E5',
				color: this.state.tcolor,
				marginTop: '15px',
			},
			img_logo: {
				maxWidth: '150px'
			},
			mtitle: {
				textAlign: 'center',
				marginBottom: '2%',
			}
			
		};

		return (
			<div>
				
				<Modal
				  isOpen={this.state.modalIsOpen}
				  Class="modalpop"
				  onRequestClose={this.state.closeModal}
				  style={customStyles}
				>
				 <button onClick={(e) => this.closeModal()} type="button" className="close_modal"><span aria-hidden="true">×</span></button>
				 <div className="modal-body">
					<h4 style={styles.mtitle} dangerouslySetInnerHTML={{ __html: this.state.modaltitle }}></h4>
					<div dangerouslySetInnerHTML={{ __html: this.state.modaltext }}></div>
				 </div>
				</Modal>
				{(this.state.store_det.status === 200) ? (
					<div style={styles.containerow} className='container'>


						{(typeof this.props.memberCookie === 'undefined' || this.props.memberCookie === '') ? (

							<form name="contactform" className="contactform" onSubmit={this.contactSubmit.bind(this)}>
								{this.state.store_det.store_logo &&
									<div className="row">
										<div className="col-md-12 div-reward-mob-logo ptb20 qw">
											<img style={styles.img_logo} alt="logo" src={this.state.store_det.store_logo}></img>
										</div>
									</div>
								}
								<div className="row">
									<div className="col-md-4"></div>
									<div className="col-md-4">
										<div className="div-popup-box">
											<div dangerouslySetInnerHTML={{ __html: this.state.store_det.earn_points_ls }} className="div-popup-head">
											</div>
											<div className="form-group">
												<label htmlFor="txt-emailq" dangerouslySetInnerHTML={{ __html: this.state.store_det.name_ls }}></label>
												<input autoComplete="nope" id="txt-emailq" type="text" className={"form-control " + (this.state.errors['name'] === 1 ? 'err_bor' : '')} name="name" onChange={this.handleChange1.bind(this, "name")} value={this.state.fields["name"]} placeholder="" />
											</div>
											<div className="form-group">
												<label htmlFor="txt-emailq" dangerouslySetInnerHTML={{ __html: this.state.store_det.email_ls }}></label>
												<input autoComplete="nope" id="txt-emailq" type="email" className={"form-control " + (this.state.errors['email'] === 1 ? 'err_bor' : '')} name="email" onChange={this.handleChange1.bind(this, "email")} value={this.state.fields["email"]} placeholder="" />
											</div>
											<div className="form-group">
												<label htmlFor="txt-emailq">{this.state.store_det.phone_ls}{this.state.store_det.phone_mandatory===0 && <span style={{ fontSize: '12px' }}> ({this.state.store_det.optional})</span>}</label>

												{/*<input id="txt-emailq" type="phone" className={"form-control " + (this.state.errors['phone'] === 1 ? 'err_bor' : '')} name="phone" onChange={this.handleChange1.bind(this, "phone")} value={this.state.fields["phone"]} placeholder="" />*/}

												<PhoneInput
													international
													countryCallingCodeEditable={false}
													defaultCountry={this.state.store_det.def_phone_country}
													onChange={this.phoneChange}
													className={"form-control " + (this.state.errors['phone'] === 1 ? 'err_bor' : '')}
													autoComplete="nope"
												/>

											</div>
											<div className="form-group txt-center">
												<button style={styles.btncontinueb} className='btn btn-reward-mob-continue' type="submit" id="submit" value="Submit">{this.state.store_det.continue_ls} {this.state.cont_loader === 1 && <i class="fa fa-spinner fa-spin"></i>}</button>
											</div>
											{this.state.store_det.gdpr_status === 1 &&
											<div className="form-check">
												  <input onChange={this.handleChange1.bind(this, "gdpr_check_mode")}  className={"form-check-input " + (this.state.errors['gdpr_check_mode'] === 1 ? 'err_bor_ot' : '')} type="checkbox" value="1" name="gdpr_check_mode" id="gdpr_check_mode" />
												  <label className="form-check-label" htmlFor="gdpr_check_mode">
													{this.state.store_det.gdpr_title}
												  </label>	
												
											</div>
											
											}
											<div className="form-group txt-center m_top1">
												{this.state.store_det.privacy_status === 1 &&
													<span onClick={(e) => this.openModal('privacy')} style={styles.privacy_style}>{this.state.store_det.privacy_title}</span>
												}
												{this.state.store_det.terms_status === 1 &&
													<span>
													{this.state.store_det.privacy_status === 1 && <span style={styles.pts_style}> | </span> }
													<span onClick={(e) => this.openModal('terms')} style={styles.privacy_style}>{this.state.store_det.terms_title}</span>
													</span>
												}
											</div>
										</div>
									</div>
								</div>
							</form>
						) : (
							<div>
								{this.state.showAnim === '' ? (
									<div>
										{this.state.store_det.store_logo &&
											<div className="row">
												<div className="col-md-12 div-reward-mob-logo ptb20 qw1">
													<img alt="logo" style={styles.img_logo} src={this.state.store_det.store_logo}></img>
												</div>
											</div>
										}
										<div className="row">
											<div className={"col-md-12 " + (!this.state.store_det.store_logo ? 'ptb20' : '')} dangerouslySetInnerHTML={{ __html: this.state.stampdata_display }}>

											</div>
										</div>
										<div className="row">
											<div className="col-md-12 div-points">
												<p><span style={styles.pts_style} dangerouslySetInnerHTML={{ __html: this.state.store_det.earned_points_ls }}></span><strong style={styles.pts_style}> : {this.state.total_points}</strong></p>
											</div>
										</div>
										<div className="row">
											<div className="col-md-4"></div>
											<div className="col-md-4">
												<Link style={styles.viewrewards} className="btn-ow btn-reward-mob" to={{ pathname: process.env.PUBLIC_URL + "/viewrewards/" + this.state.prod_id + "/" + this.state.store_id, state: { store_id1: this.state.store_id, body_color: this.state.store_det.body_color } }}><i className="fa fa-eye"></i> {this.state.store_det.view_rewards_ls}</Link>
											</div>
										</div>
										<div className="row">
											<div className="col-md-12 div-visit-store">
												<p style={styles.pts_style} dangerouslySetInnerHTML={{ __html: this.state.store_det.visit_store_earn_ls }}></p>
											</div>
										</div>
									</div>
								) : (

									<div className="row">
										<div className="col-md-12 div-reward-ani m_top25per">
											<h3 style={styles.pts_style}>{this.state.store_det.congrag_ls}</h3>
										</div>
										<div className="col-md-12 div-reward-ani">
											<img alt="logo" src="https://rewardsly.io/members/img/v1_Dark-Points.gif"></img>
										</div>
										<div className="col-md-12 div-reward-ani">
											<span style={styles.pts_style}>{this.state.store_det.you_have_earned_ls}</span>
										</div>
									</div>
								)}


							</div>

						)}

					</div>

				) : (this.state.store_det.status === 202) ? (

					<div>
						<span className="curr_unavail">Sorry! This page is currently unavailable.</span>
						<center><img alt="noimage" style={{ width: '80%' }} src={api_url + "/public/img/empty.png"} /></center>

					</div>

				) : (
					<div></div>
				)}
			</div>

		);
	}
}
const mapStateToProps = (state) => {
	return {
		memberCookie: state.passcode.memberCookie,
		rewardCookie: state.passcode.rewardCookie
	}

}
const mapDispatchToProps = (dispatch) => ({
	CookieUpdate: (action, val) => {
		//alert("cookieupdate"+val);
		dispatch({
			type: action,
			payval: val
		});
	}
})
export default connect(mapStateToProps, mapDispatchToProps)(PasscodeCookie);