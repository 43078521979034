import React from "react";
import { api_url } from '../../config';
import { StyleSheet, css } from 'aphrodite';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { setMinutes, setHours } from "date-fns";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from 'react-modal';
class Template1 extends React.Component {
	constructor(props) {

		super(props);
		console.log(props);
		this.state = {

			variation: '',
			variation_status: '',
			showvdesc: '',
			showpromo: '',
			buy_section: 0,
			card_type: '',
			for_gift: 1,
			cpn_code_status: '',
			modalIsOpen: false,
			setIsOpen: false,
			modaltext: '',
			modaltitle: '',
			gdpr_status: 0,
			gdpr_validity: 0,
			cart_det: {
				vid: "",
				amount: "",
				dollor_amt: "",
				total_amt: "",
				total_amt_sy: "",
				amt_symbol: "",
				qty_title: "",
				quantity: 1,
				name: "",
				recipient_name: '',
				email: '',
				message: '',
				phone_number: '',
				coupon_code: '',
				coupon_id: '',
				send_mode: 1,
				future_date: new Date(),
				gdpr_check_mode:0
			},
			errors: {
				name: "",
				email: '',
				recipient_name: '',
				recipient_email: '',
				phone_number: '',
				coupon_code: '',
				gdpr_check_mode :0

			}


		}
		this.dateChange = this.dateChange.bind(this);
		this.phoneChange = this.phoneChange.bind(this);


	}
	dateChange(date) {
		console.log(date);
		let cart_det = { ...this.state.cart_det };
		//const future_date = Moment(date).format('MMMM d, yyyy h:mm a');
		cart_det['future_date'] = date;
		this.setState({
			cart_det: cart_det
		})
	}
	componentDidMount() {
		const gift_id = this.props.gift_id;
		const postdata = new FormData();
		postdata.append("gift_id", gift_id);
		fetch(api_url + "/api/fetchgiftvariation", {
			method: "POST",
			body: postdata
		})
			.then(res => res.json())
			.then(
				(result) => {

					if (result.status === 200) {
						if (result.card_type === 2) {
							this.setState({
								variation: result.vt,
								variation_status: result.status,
								card_type: result.card_type,

							});
						} else {
							this.setState({
								variation_status: result.status,
								card_type: result.card_type,
							});

						}
					}

				},

				(error) => {
					this.setState({

					});
				}
			)

	}
	opendes(id) {
		//const mrk = localStorage.getItem('mrk');
		//alert(mrk);

		const stateval = this.state.showvdesc;
		if (stateval !== id) {
			this.setState({ showvdesc: id })
		} else {
			this.setState({ showvdesc: '' })
		}


	}
	openpromo() {
		const showpromo = this.state.showpromo;
		if (showpromo === '') {
			this.setState({ showpromo: 1 })
		} else {
			this.setState({ showpromo: '' })
		}

	}
	clickForGift(sentgiftmode) {
		this.setState({ for_gift: sentgiftmode })

	}
	openModal(type) {
		 let mtext;
		 let mtitle;
		 if(type === 'privacy'){
			 mtext =this.props.giftdet.privacy_content;
			 mtitle =this.props.giftdet.privacy_title;
		 }else if(type === 'terms'){
			 mtext =this.props.giftdet.terms_content;
			 mtitle =this.props.giftdet.terms_title;
		 }	 
		 this.setState({ 
			modalIsOpen: true,
			modaltext :mtext,
			modaltitle :mtitle
			
		 });
	}
	closeModal() {
		this.setState({ 
			modalIsOpen: false,
			modaltext :''
			
		 });

	}
	clickContinue() {

		let formIsValid = true;
		let cart_det = this.state.cart_det;
		let for_gift = this.state.for_gift;
		//cart_det['quantity'] = 1;
		//cart_det['name'] = 'karthick';
		console.log(cart_det);
		this.setState({ cart_de: cart_det })
		console.log(this.state.cart_det);
		let errors = {};
		if (typeof cart_det.name === "undefined" || cart_det.name === '') {
			errors["name"] = 1;
			formIsValid = false;

		}

		if (typeof cart_det.email === "undefined" || cart_det.email === '') {
			errors["email"] = 1;
			formIsValid = false;

		} else if (typeof cart_det.email !== "undefined") {

			let lastAtPos = cart_det.email.lastIndexOf('@');
			let lastDotPos = cart_det.email.lastIndexOf('.');

			if (!(lastAtPos < lastDotPos && lastAtPos > 0 && cart_det["email"].indexOf('@@') === -1 && lastDotPos > 2 && (cart_det["email"].length - lastDotPos) > 2)) {
				errors["email"] = 1;
				formIsValid = false;
			}
		}
		if(this.props.giftdet.phone_mandatory === '1'){
			if (typeof cart_det.phone_number === "undefined" || cart_det.phone_number === '') {
				errors["phone_number"] = 1;
				formIsValid = false;

			}
		}
		if (for_gift === 2) { //friend
			if (typeof cart_det.recipient_name === "undefined" || cart_det.recipient_name === '') {
				errors["recipient_name"] = 1;
				formIsValid = false;
			}
			if (typeof cart_det.recipient_email === "undefined" || cart_det.recipient_email === '') {
				errors["recipient_email"] = 1;
				formIsValid = false;

			} else if (typeof cart_det.email !== "undefined") {

				let lastAtPos = cart_det.email.lastIndexOf('@');
				let lastDotPos = cart_det.email.lastIndexOf('.');

				if (!(lastAtPos < lastDotPos && lastAtPos > 0 && cart_det["email"].indexOf('@@') === -1 && lastDotPos > 2 && (cart_det["email"].length - lastDotPos) > 2)) {
					errors["email"] = 1;
					formIsValid = false;
				}
			}
		}
		
		if(this.props.giftdet.gdpr_validity===1){
			
			if (cart_det['gdpr_check_mode'] === 0 || cart_det['gdpr_check_mode'] === '' || typeof cart_det['gdpr_check_mode'] === "undefined") {
				formIsValid = false;
				errors["gdpr_check_mode"] = 1;
			} 
		}

		this.setState({ errors: errors })
		if (formIsValid === true) {
			this.setState({ buy_section: 2 })

		}


	}
	Confir_manual_pay() {
		
		confirmAlert({
		  title: '',
		  message: this.props.lang.conf_manual_pay,
		  buttons: [
			{
			  label: this.props.lang.yes,
			  onClick: () => this.clickPay('manual') 
			},
			{
			  label: this.props.lang.no,
			  
			}
		  ]
		});
		
	}	

	clickPay(paytype) {
		const gift_id = this.props.gift_id;
		const cart_det = this.state.cart_det;

		const postdata = new FormData();
		postdata.append("gift_id", gift_id);
		postdata.append("for_gift", this.state.for_gift);
		if(this.props.giftdet.gdpr_status === 1){
				postdata.append("gdpr_status", 1);
		}
		Object.keys(cart_det).forEach((key) => {
			//Object.keys(cart_det).map(function (key) {
			if (key !== 'future_date') {
				var myitem = cart_det[key];
				postdata.append(key, myitem);
			}


		})
		if (cart_det.future_date !== '') {
			const future_date = Moment(cart_det.future_date).format('LLL');
			postdata.append("future_date", future_date);
		}


		fetch(api_url + "/api/addcart", {
			method: "POST",
			body: postdata
		})
			.then(res => res.json())
			.then(
				(result) => {
					var base_url_p = this.props.giftdet.base_url;
					var cartid = result.id;
					var base_url = base_url_p + '/pay/' + paytype + '?cartid=' + cartid;
					window.top.location.href=base_url;
					//window.parent.window.location.href = base_url;

				},

				(error) => {
					this.setState({

					});
				}
			)
	}

	addCart(vdet) {
		this.setState({ buy_section: 1 })
		let cart_det = this.state.cart_det;
		cart_det['vid'] = vdet.id;
		cart_det['amount'] = vdet.amount;
		cart_det['amt_symbol'] = vdet.amt_symbol;
		cart_det['qty_title'] = vdet.qty_title;
		cart_det['dollor_amt'] = vdet.dollor_amt;
		cart_det['total_amt_sy'] = vdet.total_amt_sy;
		cart_det['total_amt'] = vdet.total_amt;
		cart_det['varname'] = vdet.name;
		//user['instruments'].push(this.state.instruments);
		this.setState({ cart_de: cart_det })


	}
	gotoback(val) {
		
		this.setState({ buy_section:val })
		
		
	}	
	chkCouponCode() {
		let formIsValid = true;
		let errors = {};
		let cart_det = { ...this.state.cart_det };
		if (typeof cart_det.coupon_code === "undefined" || cart_det.coupon_code === '') {
			errors["coupon_code"] = 1;
			formIsValid = false;
		}

		if (formIsValid === true) {
			const gift_id = this.props.gift_id;
			const postdata = new FormData();
			postdata.append("gift_id", gift_id);
			postdata.append("coupon_id", this.props.giftdet.coupon_id);
			postdata.append("coupon_code", cart_det.coupon_code);
				

			fetch(api_url + "/api/chkcoupon", {
				method: "POST",
				body: postdata
			})

				.then(res => res.json())
				.then(
					(result) => {
						if (result.status === 200) {
							cart_det['coupon_id'] = this.props.giftdet.coupon_id;
							this.setState({
								cpn_code_status: result.status,
								variation: result.vt,
								variation_status: result.status,
								card_type: 2,
								cart_det: cart_det

							})

						} else {
							this.setState({
								cpn_code_status: result.status,
							})
						}
						//setTimeout(function(){ alert("Hello"); }, 3000);
						/* setTimeout(() => {

							this.setState({
								cpn_code_status: '',
							});

						}, 4000); */

					},

					(error) => {
						this.setState({

						});
					}
				)

		}
		this.setState({ cart_de: cart_det })
		this.setState({ errors: errors });

	}
	phoneChange(phone_number){
		
	 let cart_det = { ...this.state.cart_det };
			//const future_date = Moment(date).format('MMMM d, yyyy h:mm a');
			cart_det['phone_number'] = phone_number;
			this.setState({
				cart_det: cart_det
			}) 
	}	
	handleChange(value, ref) {
		let cart_det = { ...this.state.cart_det };
		let errors = { ...this.state.errors };

		if (ref === 'quantity') {

			const gift_id = this.props.gift_id;
			const cart = this.state.cart_det;

			const postdata = new FormData();
			postdata.append("gift_id", gift_id);
			postdata.append("quantity", value);
			postdata.append("vid", cart['vid']);
			postdata.append("dollor_amt", cart['dollor_amt']);
			postdata.append("amt_symbol", cart['amt_symbol']);
			postdata.append("amount", cart['amount']);
			fetch(api_url + "/api/qty_update", {
				method: "POST",
				body: postdata
			})

				.then(res => res.json())
				.then(
					(result) => {

						cart_det['qty_title'] = result.qty_title;
						cart_det['total_amt'] = result.total_amt;
						cart_det['amount'] = result.amount;
						cart_det['total_amt_sy'] = result.total_amt_sy;
						cart_det['dollor_amt'] = result.dollor_amt;
						cart_det['quantity'] = result.quantity;
						cart_det['qty_title'] = result.qty_title;

						this.setState({ cart_det: cart_det })

					},

					(error) => {
						this.setState({

						});
					}
				)

		} else {
			cart_det[ref] = value;
			this.setState({ cart_det: cart_det });
		}



		errors[ref] = '';
		this.setState({ errors: errors });


	}
	render() {

		const CustomInput = (props) => {
			return (
				<input
					className='form-control'
					onClick={props.onClick}
					value={props.value}
					type="text"

				/>
			)
		}
		const customStyles = {
		  content : {
			top                   : '35%',
			left                  : '50%',
			right                 : 'auto',
			bottom                : 'auto',
			marginRight           : '-50%',
			transform             : 'translate(-50%, -50%)',
			maxWidth             : '850px',
			maxHeight            : '450px'
		  }
		};
		const gift = this.props.giftdet;
		const stylegift = this.props.styletmp;
		const lang = this.props.lang;
		const styles = StyleSheet.create({
			btn_active_me: {
				color: gift.mid_bg_col,
				backgroundColor: gift.mid_text_col
			},

		});
		var qty_arr = [];
		for (let q = 1; q <= 30; q++) {

			qty_arr.push(
				<option key={q} value={q}>{q}</option>
			)
		}
		return (
			<div>
				<Modal
				  isOpen={this.state.modalIsOpen}
				  Class="modalpop"
				  onRequestClose={this.state.closeModal}
				  style={customStyles}
				>
				 <button onClick={(e) => this.closeModal()} type="button" className="close_modal"><span aria-hidden="true">×</span></button>
				 <div className="modal-body">
					<h4 style={styles.mtitle} dangerouslySetInnerHTML={{ __html: this.state.modaltitle }}></h4>
					<div dangerouslySetInnerHTML={{ __html: this.state.modaltext }}></div>
				 </div>
				</Modal>
				<div style={stylegift.mid_bg_col} className="div-gift-template-buy mid_bg_col">

					{(this.state.buy_section === 0) ? (


						(this.state.card_type === 2) ? (
							<div>
								<div className="div-card-list">
									{this.state.variation.map((vdet, index) => (
										<div key={vdet.id}>
											<div className={"row m0 div-buy1-parent " + (index > 0 ? 'btfff' : '')}>
												<div className="col-md-5 col-sm-5 col-5">
													<span onClick={() => this.opendes(vdet.id)} style={stylegift.mid_text_col}>{vdet.name}</span>
												</div>
												<div className="col-md-3 col-3 div-amt pr0">
													<label style={stylegift.mid_text_col}>
														{vdet.value &&
															<span>{vdet.value}</span>
														}
														{vdet.amt_symbol}
													</label>
												</div>
												<div className="col-md-3 col-3 pl0">
													<button style={stylegift.mid_btn} onClick={() => this.addCart(vdet)} type="button" className="btn btn-buy">{lang.buy}</button>
												</div>
												<div className="col-md-1 col-1 pl0">
													<i style={stylegift.mid_text_col} onClick={() => this.opendes(vdet.id)} className={"fa " + (this.state.showvdesc === vdet.id ? 'fa fa-angle-up' : 'fa fa-angle-down')} aria-hidden="true"></i>
												</div>
											</div>

											{this.state.showvdesc === vdet.id &&
												<div className="row m0 div-buy1-child">
													<div style={stylegift.mid_text_col} className="col-md-12" dangerouslySetInnerHTML={{ __html: vdet.description }}>

													</div>
												</div>
											}

										</div>

									))}
								</div>
								{gift.coupon_id !== 0 &&
									<div style={stylegift.bot_bg_col} className="div-gift-template-footer">
										<div className="div-limited">
											<div className="row">
												<div className="col-md-11 col-11">
													<p onClick={() => this.openpromo()} style={stylegift.bot_text_col}>{lang.coupon_code}</p>

												</div>
												<div className="col-md-1 col-1 pl0">
													<i onClick={() => this.openpromo()} style={stylegift.bot_text_col} className="fa fa-angle-down" aria-hidden="true"></i>
												</div>
											</div>
										</div>
										{this.state.showpromo === 1 &&
											<div className="div-limited-child">
												<div className="row">
													<div className="col-md-8 col-8">
														<input className={"form-control " + (this.state.errors['coupon_code'] === 1 ? 'err_bor' : '')} onChange={(e) => this.handleChange(e.target.value, 'coupon_code')} style={stylegift.bot_text_col} id="coupon_code" name="coupon_code" placeholder={lang.coupon_code} value={this.state.cart_det.coupon_code} />

														{this.state.cpn_code_status === 201 &&
															<span className="inval_cpn_cls">{lang.invalid_coupon_code}</span>
														}
														{this.state.cpn_code_status === 200 &&
															<span className="succ_cpn_cls"><i className="fa fa-check" aria-hidden="true"></i>{lang.coupon_code_app}</span>
														}
													</div>
													<div className="col-md-4 col-4 res-p0">
														<button onClick={() => this.chkCouponCode()} style={stylegift.bot_btn} className="btn" type="button">{lang.check}</button>
													</div>
												</div>
												<div className="row">
													{gift.coupon_desc !== '' &&

														<p className="coupon_desc" style={stylegift.bot_text_col}>{gift.coupon_desc}</p>

													}
												</div>
											</div>
										}

									</div>
								}

							</div>

						) : (

								<div></div>

							)





					) : (this.state.buy_section === 1) ? (
						<div style={stylegift.mid_text_col} className="div-buy-section">
							<div className="div-buy-heading">
								<h2>{this.state.cart_det.varname}</h2>
								<h3>{this.state.cart_det.qty_title}</h3>
								<span>{gift.for_use_at_ls}</span>
							</div>
							<div className="div-buy-btns">
								<div className="row">

									<div className="col-md-6 col-6 p0">
										<button onClick={() => this.clickForGift(1)} style={stylegift.mid_btn_bg} className={"btn-select " + (this.state.for_gift === 1 ? css(styles.btn_active_me) : '')}>
											<i className="fa fa-user" aria-hidden="true"></i>
											{lang.for_me}
										</button>
									</div>
									<div className="col-md-6 col-6 p0">
										<button onClick={() => this.clickForGift(2)} style={stylegift.mid_btn_bg} className={"btn-select " + (this.state.for_gift === 2 ? css(styles.btn_active_me) : '')}>
											<i className="fa fa-gift" aria-hidden="true"></i>
											{lang.for_friend}
										</button>
									</div>

								</div>
							</div>
							<div className="div-buy-form m_top2">

								<div className="form-group mb-4">
									<label forhtml="selquantity">{lang.quantity}</label>
									<select className="form-control" onChange={(e) => this.handleChange(e.target.value, 'quantity')} name="" id="selquantity">
										{qty_arr}
									</select>
								</div>
								<div className="form-group mb-4">
									<label forhtml="name">{lang.your_name}</label>
									<input className={"form-control " + (this.state.errors['name'] === 1 ? 'err_bor' : '')} onChange={(e) => this.handleChange(e.target.value, 'name')} value={this.state.cart_det.name} type="text" id="name" name="name" placeholder={lang.e_your_name}></input>
								</div>
								<div className="form-group mb-4">
									<label forhtml="email">{lang.your_email}</label>
									<input onChange={(e) => this.handleChange(e.target.value, 'email')} value={this.state.cart_det.email} type="text" className={"form-control " + (this.state.errors['email'] === 1 ? 'err_bor' : '')} id="email" name="email" placeholder={lang.e_your_email}></input>
								</div>
								{/*<div className="form-group mb-4">
									<label forhtml="phone_number">{lang.your_ph_number}<span style={{fontSize:'12px'}}> ({lang.optional})</span></label>
									<input onChange={(e) => this.handleChange(e.target.value, 'phone_number')} value={this.state.cart_det.phone_number} type="number" className={"form-control " + (this.state.errors['phone_number'] === 1 ? 'err_bor' : '')} id="phone_number" name="phone_number" placeholder={lang.e_your_ph_number}></input>
								</div> */}
								<div className="form-group mb-4">
								<label forhtml="phone_number">{lang.your_ph_number} {gift.phone_mandatory===0 && <span style={{fontSize:'12px'}}> ({lang.optional})</span>}</label>
								<PhoneInput
								  international
								  countryCallingCodeEditable={false}
								  defaultCountry={gift.def_phone_country}
								  onChange={this.phoneChange}
								  className={"form-control " + (this.state.errors['phone_number'] === 1 ? 'err_bor' : '')}
								/>
															
								</div>
								{this.state.for_gift === 2 &&
									//friend
									<div>
										<div className="form-group mb-4">
											<label forhtml="recipient_name">{lang.recipient_name}</label>
											<input onChange={(e) => this.handleChange(e.target.value, 'recipient_name')} value={this.state.cart_det.recipient_name} type="text" className={"form-control " + (this.state.errors['recipient_name'] === 1 ? 'err_bor' : '')} id="recipient_name" name="recipient_name" placeholder={lang.e_recipient_name}></input>
										</div>
										<div className="form-group mb-4">
											<label forhtml="recipient_name">{lang.recipient_email}</label>
											<input onChange={(e) => this.handleChange(e.target.value, 'recipient_email')} value={this.state.cart_det.recipient_email} type="text" className={"form-control " + (this.state.errors['recipient_email'] === 1 ? 'err_bor' : '')} id="recipient_email" name="recipient_email" placeholder={lang.e_recipient_email}></input>
										</div>
										<div className="form-group mb-4">
											<label forhtml="message">{lang.message}</label>
											<textarea onChange={(e) => this.handleChange(e.target.value, 'message')} value={this.state.cart_det.message} className="form-control" name="message" id="message" placeholder={lang.message} rows="3"></textarea>
										</div>


									</div>

								}
								{this.state.for_gift === 2 &&
									<div>
										<div className="form-group mb-4">

											<label forhtml="send_mode">{lang.send_mode}</label>
											<select className="form-control" onChange={(e) => this.handleChange(e.target.value, 'send_mode')} name="" id="send_mode">
												<option key="1" value="1">{lang.send_instantly}</option>
												<option key="2" value="2">{lang.send_future_date}</option>
											</select>
										</div>
										{this.state.cart_det.send_mode === '2' &&
											<div className="form-group mb-4">
												<DatePicker
													customInput={<CustomInput />}
													selected={this.state.cart_det.future_date}
													onChange={this.dateChange}
													showTimeSelect
													timeFormat="h:mm aa"
													timeIntervals={60}
													timeCaption="time"
													minDate={new Date()}
													className="form-control"
													dateFormat="MMMM d, yyyy h:mm aa"
													includeTimes={[
														setHours(setMinutes(new Date(), 0), 6),
														setHours(setMinutes(new Date(), 0), 8),
														setHours(setMinutes(new Date(), 0), 10),
														setHours(setMinutes(new Date(), 0), 12),
														setHours(setMinutes(new Date(), 0), 14),
														setHours(setMinutes(new Date(), 0), 16),
														setHours(setMinutes(new Date(), 0), 18),
														setHours(setMinutes(new Date(), 0), 20),
														setHours(setMinutes(new Date(), 0), 22),
													]}
												/>
											</div>
										}
									</div>
								}

								<div className="form-group mb-4 col-md-12">
									<div className="row">
									<div className="col-md-6">
										<button onClick={() => this.clickContinue()} style={stylegift.mid_btn_bg} className="btn btn-continue">{lang.continue} <i style={stylegift.mid_btn_txt_col} className="fa fa-arrow-right" aria-hidden="true"></i></button>
									</div>
								    <div className="col-md-6">
										<button onClick={() => this.gotoback(0)} style={stylegift.mid_btn_bg} className="btn btn-continue"><i style={stylegift.mid_btn_txt_col} className="fa fa-arrow-left" aria-hidden="true"></i> {lang.back}</button>
									</div>	
									</div>	
								</div>
								{!!gift.gdpr_status  &&
								<div className="form-check">
									  <input onChange={(e) => this.handleChange(e.target.value, 'gdpr_check_mode')} className={"form-check-input " + (this.state.errors['gdpr_check_mode'] === 1 ? 'err_bor_ot' : '')} type="checkbox" value="1" name="gdpr_check_mode" id="gdpr_check_mode" />
									  <label className="form-check-label" htmlFor="gdpr_check_mode">
										{gift.gdpr_title}
									  </label>	
									
								</div>
								
								}
								
								<div className="form-group txt-center m_top1">
									{!!gift.privacy_status &&
										<span style={{cursor: "pointer"}} onClick={(e) => this.openModal('privacy')}> {gift.privacy_title}</span>
									}
									{!!gift.terms_status &&
										<span>
										{gift.privacy_status === 1 && <span> | </span> }
										<span style={{cursor: "pointer"}} onClick={(e) => this.openModal('terms')}>{gift.terms_title}</span>
										</span>
									}
								</div>
								
							</div>

						</div>
					) : (this.state.buy_section === 2) ? (
						<div style={stylegift.mid_text_col} className="div-buy-section">

							<div className="row">
								<div className="col-md-12 text-center">
									<h3>{lang.your_order_summary}</h3>
								</div>
								<div className="col-md-6 col-6 text-left m_top1">
									<div className="div-order-head">{this.state.cart_det.qty_title}</div>
								</div>
								<div className="col-md-6 col-6 text-right m_top1">
									<div className="div-order-head">{this.state.cart_det.total_amt_sy}</div>
								</div>
								<div className="col-md-12 text-left m_top1">
									<div className="div-order-subhead">{lang.your_name}: <span>{this.state.cart_det.name}</span></div>
								</div>
							</div>
							<div className="row">
								{(this.state.for_gift === 1) ? (
									<div className="col-md-12 text-left">
										<div className="div-order-subhead">{lang.by_email}: <span>{this.state.cart_det.email}</span></div>
									</div>
								) : (
										<div>
											<div className="col-md-12 text-left">
												<div className="div-order-subhead">{lang.recipient_name}:<span>{this.state.cart_det.recipient_name}</span></div>
											</div>
											<div className="col-md-12 text-left">
												<div className="div-order-subhead">{lang.by_email}:<span>{this.state.cart_det.recipient_email}</span></div>
											</div>
											<div className="col-md-12 text-left">
												<div className="div-order-subhead">{lang.personal_message}:<span>{this.state.cart_det.message}</span></div>
											</div>
										</div>

									)}
							</div>
							<div className="row">

								<div className="col-md-6 col-6 text-left m_top2">
									<h3>{lang.total}</h3>
								</div>
								<div className="col-md-6 col-6 text-right m_top2">
									<h3>{this.state.cart_det.total_amt_sy}</h3>
								</div>

							</div>

							{gift.razor === 1 &&
								<div className="form-group mb-3">
									<button onClick={() => this.clickPay('razorpay')} style={stylegift.mid_btn_bg} className="btn btn-continue-payment"><img className="" src={api_url + "/img/integration/14.png"} alt="Paypal"></img> <p>{lang.checkout_razorpay}</p></button>

								</div>
							}

							{(gift.razor === 1 && (gift.paypal === 1 || gift.stripe === 1)) &&
								<div className="div-pay-or">
									<p>-- {lang.or} --</p>
								</div>
							}

							{gift.paypal === 1 &&
								<div className="form-group mb-3">
									<button onClick={() => this.clickPay('paypal')} style={stylegift.mid_btn_bg} className="btn btn-continue-payment"><img className="" src={api_url + "/img/integration/17.png"} alt="Paypal"></img> <p>{lang.checkout_paypal}</p></button>
								</div>
							}

							{(gift.paypal === 1 && (gift.stripe === 1 || gift.razor === 1 )) &&
								<div className="div-pay-or">
									<p>-- {lang.or} --</p>
								</div>
							}

							{gift.stripe === 1 &&
								<div className="form-group mb-3">
									<button onClick={() => this.clickPay('stripe')} style={stylegift.mid_btn_bg} className="btn btn-continue-payment"><img className="" src={api_url + "/img/integration/19.png"} alt="Paypal"></img> <p>{lang.checkout_stripe}</p></button>
								</div>
							}
							
							
							{(gift.flutterwave === 1 && (gift.paypal === 1 || gift.razor === 1 || gift.stripe === 1  )) &&
								<div className="div-pay-or">
									<p>-- {lang.or} --</p>
								</div>
							}
							
							{gift.flutterwave === 1 &&
								<div className="form-group mb-3">
									<button onClick={() => this.clickPay('flutterwave')} style={stylegift.mid_btn_bg} className="btn btn-continue-payment"><img className="" src={api_url + "/img/integration/flutterwave.jpeg"}  alt="flutterwave"></img> <p>{lang.checkout_flutterwave}</p></button>
									
								</div>
							}
							
							
							{(gift.manual_payment === 1 && (gift.paypal === 1 || gift.razor === 1 || gift.stripe === 1 || gift.flutterwave ===1 )) &&
								<div className="div-pay-or">
									<p>-- {lang.or} --</p>
								</div>
							}
							
							{gift.manual_payment === 1 &&
								<div className="form-group mb-3">
									<button onClick={() => this.Confir_manual_pay()} style={stylegift.mid_btn_bg} className="btn btn-continue-payment"><img className="" src={api_url + "/img/integration/16.png"}  alt="Manual"></img> <p>{lang.manual_payment}</p></button>
									
								</div>
							}
						</div>

					) : (

									<div></div>
								)}

					{!!gift.terms &&
						<div className="col-md-12 bt pl0 pr0">
							<div style={stylegift.bot_bg_col} className="div-footer-description">
								<p style={stylegift.bot_text_col}>{gift.terms}</p>
							</div>

						</div>
					}



					<div className="col-md-4"></div>

				</div>
			</div>

		);
	}
}


export default Template1;